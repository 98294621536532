<template>
  <div class="col-12 col-lg-6 g c" v-if="school.school">
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <p>بيانات عامة</p>
            <table class="table table-sm table-hover table-bordered">
                <tbody>
                    <tr>
                        <th>
                            ID
                        </th>
                        <th>
                            {{ school.school.school_id }}
                        </th>
                    </tr>
                    <tr>
                        <th>
                            اسم المدرسة
                        </th>
                        <th>
                            <span v-if="school.school.m" class="text-danger">[مشرف]</span>
                            {{ school.school.school_name }}
                        </th>
                    </tr>
                    <tr>
                        <th>
                            اسم صاحب الحساب
                        </th>
                        <th>
                            {{ school.school.admin_name }}
                        </th>
                    </tr>
                    <tr>
                        <th>
                            تاريخ الانضمام
                        </th>
                        <th>
                            {{ school.school.date }}
                        </th>
                    </tr>
                    <tr>
                        <th>
                            تاريخ انتهاء الاشتراك
                        </th>
                        <th>
                            <input type="text" style="border:none" v-model="school.school.exdate" @change="changeExdate()">
                        </th>
                    </tr>
                    <tr>
                        <th>
                            ملاحظات على المدرسة
                        </th>
                        <th>
                            <textarea style="border:none" v-model="school.school.notes" @change="changeNotes()" rows="3"></textarea>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            طلبات الاشتراك
                        </th>
                        <th>
                            <button class="btn btn-sm btn-primary" @click="$router.push('/orders#' + school.school.school_id)"><i class="fa fa-list"></i> عرض الطلبات</button>
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <p>مدارس اخرى لنفس الاسم</p>
            <table class="table table-sm table-hover table-bordered">
                <tbody>
                    <tr v-for="a in school.another" :key="a._id">
                        <th v-if="a.school_id != school.school.school_id">
                            <a href="javascript:;" @click="$router.push('/school/' + a.school_id)">{{ a.school_name }}</a>
                        </th>
                        <th v-if="a.school_id != school.school.school_id">
                            <a href="javascript:;" @click="$router.push('/school/' + a.school_id)">{{ a.school_id }}</a>
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
           school: {} 
        }
    },
    created(){
        var g = this;
        $.post(api + '/admin/school', {
            jwt: localStorage.getItem("jwt"),
            id: window.location.href.split("/school/")[1]
        })
        .then(function(response){
            if(response.status == 100){
                g.school = response.response
            }else{
                alert("حدث خطأ.")
            }
        })
        .fail(function(){
            g.loading = false
            g.messages = []
            alert("حدث خطأ")
        })
    },
    methods: {
        changeExdate(){
            var g = this;
            $.post(api + '/admin/school/change-exdate', {
                jwt: localStorage.getItem("jwt"),
                id: window.location.href.split("/school/")[1],
                exdate: this.school.school.exdate
            })
            .then(function(response){
                if(response.status == 100){
                    alert("تم بنجاح!")
                }else{
                    alert("حدث خطأ.")
                }
            })
            .fail(function(){
                g.loading = false
                g.messages = []
                alert("حدث خطأ")
            })
        },
        changeNotes(){
            var g = this;
            $.post(api + '/admin/school/change-notes', {
                jwt: localStorage.getItem("jwt"),
                id: window.location.href.split("/school/")[1],
                notes: this.school.school.notes
            })
            .then(function(response){
                if(response.status == 100){
                    alert("تم بنجاح!")
                }else{
                    alert("حدث خطأ.")
                }
            })
            .fail(function(){
                g.loading = false
                g.messages = []
                alert("حدث خطأ")
            })
        },
    }
}
</script>

<style>

</style>